<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Playa Resorts Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader('a27adfd1-a837-4704-97c4-5b910df8afc6')

const {
  resorts,
  brands,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#1d3091' })
provide('currentResort', currentResort)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white playaresorts-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />
        <div id="playa-content">
          <PlayaresortsNavigation
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
            :resorts="resorts?.data"
            :brands="brands?.data"
            class="font-sans"
            role="landmark"
          />

          <slot />

          <PlayaresortsFooter
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
            :footer-company-menu="footerCompanyMenu?.data"
            :footer-resources-menu="footerResourcesMenu?.data"
            :footer-terms-menu="footerTermsMenu?.data"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/playaresorts.scss';
</style>
